<template>
  <div v-editable="blok">
    <Carousel
      ref="myCarousel"
      v-model="currentSlide"
      v-bind="{ breakpoints, ...blok }"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <slot />
      <slide v-for="(blok, i) in blok.blocks" :key="i">
        <StoryblokComponent :key="blok._uid" :blok="blok" />
      </slide>

      <template #addons>
        <navigation v-if="blok.navigation && !display.smAndDown.value" />
        <pagination v-if="blok.pagination || display.smAndDown.value" />
      </template>
    </Carousel>
  </div>
</template>

<script setup>
import { useDisplay } from "vuetify";
const display = useDisplay();

const props = defineProps({
  blok: Object,
  settings: Object,
  modelValue: Number,
});

const alignItems = computed(() => props.blok.align_items || "center");

function convertKeysToInteger(obj) {
  const result = {};

  Object.keys(unref(obj)).forEach((key) => {
    // Konvertiere den Schlüssel von einem String in einen Integer
    const intKey = parseInt(key, 10);

    // Füge den neuen Schlüssel und den Wert dem Resultat hinzu
    result[intKey] = obj[key];
  });

  return result;
}

const emit = defineEmits(["update:modelValue"]);
const currentSlide = ref(props.modelValue || 0);
watch(
  () => props.modelValue,
  (value) => {
    currentSlide.value = value;
  },
);
const breakpoints = {
  // 500px and up
  530: {
    itemsToShow: 2,
    itemsToScroll: 2,
    snapAlign: "start",
  },
  // 768px and up
  769: {
    itemsToShow: props.blok.itemsToShow || 3,
    itemsToScroll: props.blok.itemsToScroll || 3,
    snapAlign: "start",
  },
};
</script>

<style>
:root {
  --vc-clr-primary: #fff;
  --vc-clr-secondary: #ffffff45;
  --vc-pgn-width: 12px;
  --vc-pgn-height: 4px;
  --vc-pgn-margin: 4px;
}
</style>

<style lang="scss">
.carousel__slide {
  align-items: v-bind(alignItems);

  &:not(.carousel__slide--visible) {
    height: 0px;
  }
}

.carousel__pagination {
}

.carousel__prev,
.carousel__next {
  opacity: 0.5;
  background-color: transparent;
  padding: 0 15px;
  transition: all 300ms ease-in-out;
  border-radius: 10px;

  &:hover {
    opacity: 1;
    background-color: #00000040;
    color: inherit;
  }
}

.slide {
  max-width: 1350px;
}
</style>
